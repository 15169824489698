export default function Gas(props) {
  const { handleInput, dataForm } = props;
  const selectCheckSub = (el, type) => {
      console.log(el);
    if (dataForm[type] === el) return true;
  };
  return (
    <div className="calc_gas step_box">
      <h5 className="question small">유종을 선택해주세요.</h5>
      <ul className="category_list d-flex select_radio">
        {[
          ["도시가스", "LNG"],
          ["도시가스", "LPG"],
          ["보일러등유", "kerosene"],
          ["연탄", "briquette"],
        ].map((el, idx) => {
            const valueToCheck = el[0] === "도시가스" ? `${el[0]}(${el[1]})` : el[0];
            const isChecked = selectCheckSub(valueToCheck, "유종");
            return (
            <li key={idx}>
                <label htmlFor={"gas_" + el[1]} className="calc_radio">
                    <input
                        type="radio"
                        id={"gas_" + el[1]}
                        name="gas_oil"
                        data-value={valueToCheck}
                        data-type="유종"
                        onClick={handleInput}
                        checked={isChecked || false}  // 초기값을 명확히 지정
                        readOnly
                    />
                    {el[0] === "도시가스" ? (
                        <span>
                    {el[0]}
                            <br/>
                            {el[1]}
                  </span>
                    ) : (
                        <span>{el[0]}</span>
                    )}
                </label>
            </li>
            );
        })}
      </ul>
        <div className="q_item small">
            <h5 className="question small">한달 사용량을 입력해주세요.</h5>
            <div className="input_box input_ty02 d-flex flex-ac">
                <input type="text" placeholder="숫자만 입력" data-type="사용량" value={dataForm["사용량"] || ""} onChange={handleInput} />
          <span>㎥/월</span>
        </div>
      </div>
      <div className="q_item small">
        <h5 className="question small">거주 인원 수를 입력해주세요.</h5>
        <div className="input_box input_ty02 d-flex flex-ac">
          <input type="text" placeholder="숫자만 입력" data-type="거주인원수" value={dataForm["거주인원수"] || ""} onChange={handleInput} />
          <span>명</span>
        </div>
      </div>
    </div>
  );
}
