export default function TravelType06(props) {
  const { handleInput, dataForm } = props;
  return (
    <div className="calc_travel">
      <div className="travel_wrap step_box step06">
        <div className="q_item">
          <h5 className="question small">동승 인원을 입력해주세요.</h5>
          <div className="input_box input_ty02 d-flex flex-ac">
            <input type="text" placeholder="숫자만 입력" data-type="동승인원" value={dataForm["동승인원"] || ""} onChange={handleInput} />
            <span>명</span>
          </div>
        </div>
        <div className="q_item">
          <h5 className="question small">이용 횟수를 입력해주세요.</h5>
          <div className="input_box input_ty02 d-flex flex-ac">
            <input type="text" placeholder="숫자만 입력" data-type="이용횟수" value={dataForm["이용횟수"] || ""} onChange={handleInput} />
            <span>회/총</span>
          </div>
        </div>
      </div>
    </div>
  );
}
