import { useState, useEffect } from "react";
import { Link, useLocation  } from "react-router-dom";
import { useCheckToken } from "../../hooks/useCheckToken";
import Spiner from "../../components/SpinerAni";
import "swiper/css/pagination";
import noPost from "../../assets/img/no_post.png";
export default function ChallengeList() {
  const { mb_no, resData, postData } = useCheckToken(true);
  const [challengeList, setChallengeList] = useState([]);
  const [isLoadData, setIsLoadData] = useState(false);
  


  const loadPost = async () => {
    const res = await postData("challenge/list", {
      mb_no,
    });
    if (res.code === 200) {
      setChallengeList(res.data.challengeList);
      setIsLoadData(true);
      console.log(challengeList);
    }
  };

  useEffect(() => {
    loadPost();
  }, []);

  return (
    <div id="challenge" className="container challenge">
      <div className="inner">
        <div className="filter_box d-flex flex-ac flex-js">
          <span className="title">참여할 챌린지를 선택해주세요.</span>
        </div>
        {/* //글 */}
        {challengeList.map((el, idx) => {
          return (
              <Link key={el.idx} to={"/ChallengeWrite/" + el.idx+ "?challengeName=" + el.ch_title}>
                <h4 className="ch_tit">
                  <p>{el.start_at} ~ {el.end_at}</p>
                  <p>{el.ch_title}</p>
                </h4>
              </Link>
          );
        })}
        {/* 글 없을때 */}
        {!isLoadData && <Spiner />}
        {isLoadData && !challengeList[0] && (
          <div className="no_data_wrap">
            <img src={noPost}></img>
          </div>
        )}
        {/* //글 없을때 */}
      </div>
    </div>
  );
}
