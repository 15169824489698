export default function Trash(props) {
  const { handleInput, dataForm } = props;
  return (
    <div className="calc_trash step_box">
      <div className="q_item small">
        <h5 className="question small">지난 달 , 몇 L의 쓰레기를 배출 했나요?</h5>
        <div className="input_box input_ty02 d-flex flex-ac">
          <input type="text" placeholder="숫자만 입력" data-type="배출량" value={dataForm["배출량"] || ""} onChange={handleInput} />
          <span>L/월</span>
        </div>
      </div>
      <div className="q_item small">
        <h5 className="question small">거주 인원 수를 입력해주세요.</h5>
        <div className="input_box input_ty02 d-flex flex-ac">
          <input type="text" placeholder="숫자만 입력" data-type="거주인원수" value={dataForm["거주인원수"] || ""} onChange={handleInput} />
          <span>명</span>
        </div>
      </div>
    </div>
  );
}
