import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useRecoilState } from "recoil";
import { isLoginAtom } from "./recoil/atoms";
import { useBackEvent } from "./hooks/bundle_hooks";
import ScrollTop from "./components/ScrollTop";
import TostAlert from "./components/TostAlert";
import ServicePage from "./routes/ServicePage";
import Splash from "./routes/intro/Splash";
import Intro from "./routes/intro/Intro";
import Header from "./routes/layout/Header";
import Gnb from "./routes/layout/Gnb";
import SideMenu from "./routes/layout/SideMenu";
import Login from "./routes/login/Login";
import JoinAgree from "./routes/join/JoinAgree";
import JoinAgreeDetail from "./routes/join/JoinAgreeDetail";
import JoinPass from "./routes/join/JoinPass";
import JoinEssential from "./routes/join/JoinEssential";
import JoinSelect from "./routes/join/JoinSelect";
import JoinComplete from "./routes/join/JoinComplete";
import FindID from "./routes/find_account/FindID";
import FindIDComplete from "./routes/find_account/FindIDComplete";
import FindPw from "./routes/find_account/FindPw";
import PwResetting from "./routes/find_account/PwResetting";
import Main from "./routes/main/Main";
// import MyDevice from "./routes/device/MyDevice";
// import MyDeviceDetail from "./routes/device/MyDeviceDetail";
// import DeviceAdd from "./routes/device/DeviceAdd";
// import DeviceRecent from "./routes/device/DeviceRecent";
// import DeviceQR from "./routes/device/DeviceQR";
// import DeviceFind from "./routes/device/DeviceFind";
// import DeviceConnect from "./routes/device/DeviceConnect";
// import DeviceConnect02 from "./routes/device/DeviceConnect02";
// import DeviceConnect03 from "./routes/device/DeviceConnect03";
import DeviceInit from "./routes/device/DeviceInit";
import DeviceConnect from "./routes/device/DeviceConnect";
import DeviceBluetoothStep1 from "./routes/device/DeviceBluetoothStep1";
import DeviceBluetoothStep2 from "./routes/device/DeviceBluetoothStep2";
import DeviceBluetoothLoading from "./routes/device/DeviceBluetoothLoading";
import DeviceBluetoothDone from "./routes/device/DeviceBluetoothDone";
import DeviceHome from "./routes/device/DeviceHome";
import RecommendProduct from "./routes/category/RecommendProduct";
import Mypage from "./routes/mypage/Mypage";
import MypageEssential from "./routes/mypage/MypageEssential";
import MypageSelect from "./routes/mypage/MypageSelect";
import Withdrawal from "./routes/mypage/Withdrawal";
import WithdrawalComplete from "./routes/mypage/WithdrawalComplete";
import MyActive from "./routes/mypage/MyActive";
import Calculator from "./routes/calculator/Calculator";
import Challenge from "./routes/challenge/Challenge";
import ChallengeList from "./routes/challenge/ChallengeList";
import ChallengeDetail from "./routes/challenge/ChallengeDetail";
import ChallengeWrite from "./routes/challenge/ChallengeWrite";
import ChallengeEdit from "./routes/challenge/ChallengeEdit";
import Ranking from "./routes/ranking/Ranking";
import EventNews from "./routes/community/EventNews";
import EventNewsDetail from "./routes/community/EventNews_detail";
import TipReference from "./routes/community/TipReference";
import TipReferenceDetail from "./routes/community/TipReference_detail";
import FAQ from "./routes/community/FAQ";
import Qna from "./routes/community/Qna";

// css
import "./assets/scss/App.css";
import "swiper/css";
import { useEffect } from "react";
import axios from "axios";

function App() {
  const [isLogin] = useRecoilState(isLoginAtom);
  const { pathname } = useLocation();
  const { addWindowBackEvent } = useBackEvent();
  const [cookies, setCookie] = useCookies();
  const [sideMenu, setSideMenu] = useState(false);

  const toggleSideMenu = () => setSideMenu(!sideMenu);

  const agentData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    const ip = res.data.IPv4;
    const referrer = document.referrer;
    const agentData = window.navigator.userAgentData;
    const agentFullData = window.navigator.userAgent;
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
    let accessInfo = {
      referrer,
      os: agentData.platform,
      device: agentData.mobile ? "mobile" : "pc",
      browser: agentData?.brands[2]?.brand || "",
      agent: agentFullData,
    };
    if (!cookies.ip || cookies.ip !== ip) {
      setCookie("ip", ip, { path: "/", expires });
      accessInfo.ip = ip;
    }
  };


  useEffect(() => {
    agentData();

  }, []);

  useEffect(() => {
    addWindowBackEvent();
  }, [pathname]);

  return (
    <>
      <ScrollTop />
      <Header sideToggle={toggleSideMenu} />
      {sideMenu && <SideMenu sideClose={toggleSideMenu} />}
      <TostAlert />
      <Routes>
        <Route path="/ServicePage" element={<ServicePage />}></Route>
        <Route path="/Splash" element={<Splash />}></Route>
        <Route path="/Intro" element={<Intro />}></Route>
        <Route path="/" element={<Main />}></Route>
        <Route path="/Login" element={<Login />}></Route>
        <Route path="/JoinAgree" element={<JoinAgree />}></Route>
        <Route path="/JoinAgreeDetail" element={<JoinAgreeDetail />}></Route>
        <Route path="/JoinPass" element={<JoinPass />}></Route>
        <Route path="/JoinEssential" element={<JoinEssential />}></Route>
        <Route path="/JoinSelect" element={<JoinSelect />}></Route>
        <Route path="/JoinComplete" element={<JoinComplete />}></Route>
        <Route path="/FindID" element={<FindID />}></Route>
        <Route path="/FindIDComplete" element={<FindIDComplete />}></Route>
        <Route path="/FindPw" element={<FindPw />}></Route>
        <Route path="/PwResetting" element={<PwResetting />}></Route>
        {/* <Route path="/MyDevice" element={<MyDevice />}></Route>
        <Route path="/DeviceDetail" element={<MyDeviceDetail />}></Route>
        <Route path="/DeviceAdd" element={<DeviceAdd />}></Route>
        <Route path="/DeviceRecent" element={<DeviceRecent />}></Route>
        <Route path="/DeviceQR" element={<DeviceQR />}></Route>
        <Route path="/DeviceFind" element={<DeviceFind />}></Route>
        <Route path="/DeviceConnect" element={<DeviceConnect />}></Route>
        <Route path="/DeviceConnect02" element={<DeviceConnect02 />}></Route>
        <Route path="/DeviceConnect03" element={<DeviceConnect03 />}></Route> */}
        <Route path="/DeviceInit" element={<DeviceInit />}></Route>
        <Route path="/DeviceConnect" element={<DeviceConnect />}></Route>
        <Route
          path="/DeviceBluetoothStep1"
          element={<DeviceBluetoothStep1 />}
        ></Route>
        <Route
          path="/DeviceBluetoothStep2"
          element={<DeviceBluetoothStep2 />}
        ></Route>
        <Route
          path="/DeviceBluetoothLoading"
          element={<DeviceBluetoothLoading />}
        ></Route>
        <Route
          path="/DeviceBluetoothDone"
          element={<DeviceBluetoothDone />}
        ></Route>
        <Route path="/DeviceHome/:id" element={<DeviceHome />}></Route>
        <Route path="/RecommendProduct" element={<RecommendProduct />}></Route>
        <Route path="/Mypage" element={<Mypage />}></Route>
        <Route path="/MypageEssential" element={<MypageEssential />}></Route>
        <Route path="/MypageSelect" element={<MypageSelect />}></Route>
        <Route path="/MyActive/:id" element={<MyActive />}></Route>
        <Route path="/Withdrawal" element={<Withdrawal />}></Route>
        <Route
          path="/WithdrawalComplete"
          element={<WithdrawalComplete />}
        ></Route>
        <Route path="/Calculator/:id" element={<Calculator />}></Route>
        <Route path="/ChallengeMain" element={<Challenge />}>
          <Route path="Detail" element={<ChallengeDetail />}></Route>
        </Route>
        <Route path="/ChallengeList" element={<ChallengeList />}></Route>
        {/* <Route path="/ChallengeDetail" element={<ChallengeDetail />}></Route> */}
        <Route path="/ChallengeWrite/:id" element={<ChallengeWrite />}></Route>
        <Route path="/ChallengeEdit" element={<ChallengeEdit />}></Route>
        <Route path="/Ranking/:id" element={<Ranking />}></Route>
        <Route path="/EventNews" element={<EventNews />}></Route>
        <Route
          path="/EventNewsDetail/:id/:id"
          element={<EventNewsDetail />}
        ></Route>
        <Route path="/TipReference" element={<TipReference />}></Route>
        <Route
          path="/TipReferenceDetail"
          element={<TipReferenceDetail />}
        ></Route>
        <Route path="/FAQ" element={<FAQ />}></Route>
        <Route path="/Qna/:id" element={<Qna />}></Route>
      </Routes>
      <Gnb sideState={sideMenu} sideClose={toggleSideMenu} />
    </>
  );
}

export default App;
