import { useSelectBox } from "../../../hooks/bundle_hooks";

export default function TravelType09(props) {
  const { handleInput, dataForm } = props;
  const { selectList, handleSelectBox } = useSelectBox({ start: false, arrive: false });
  const route = {
    김포: ["일본-하네다", "일본-오사카", "중국-상하이"],
    인천: [
      "일본-구마모토",
      "일본-나고야",
      "일본-다카마쓰",
      "일본-도야마",
      "일본-나리타",
      "일본-마쓰야마",
      "일본-미야자키",
      "일본-센다이",
      "일본-아사히카와",
      "일본-오사카",
      "일본-오키나와",
      "일본-요나고",
      "일본-후쿠시마",
      "일본-후쿠오카",
      "일본-히로시마",
      "일본-하네다",
      "일본-시즈오카",
      "중국-창사",
      "중국-광저우",
      "중국-구이린",
      "중국-난징",
      "중국-베이징",
      "중국-청두",
      "중국-상하이",
      "중국-선전",
      "중국-옌지",
      "중국-엔타이",
      "중국-웨이하이",
      "중국-창춘",
      "중국-충칭",
      "중국-텐진",
      "중국-칭다오",
      "중국-하얼빈",
      "중국-항저우",
      "중국-다렌",
      "중국-시안",
      "중국-홍콩",
      "미국-뉴욕",
      "미국-로스엔젤리스",
      "미국-샌프란시스코",
      "미국-시애틀",
      "미국-시카고",
      "유럽-런던",
      "유럽-프랑크프루트",
      "유럽-파리",
      "오세아니아-사이판",
      "오세아니아-시드니",
      "오세아니아-팔라우",
      "기타-사할린",
      "기타-하바로스크",
      "기타-타이페이",
      "기타-마닐라",
      "기타-방콕",
      "기타-세부",
      "기타-싱가포르",
      "기타-씨엠립",
      "기타-코타키나발루",
      "기타-클라크필드",
      "기타-푸켓",
      "기타-프놈펜",
      "기타-하노이",
      "기타-호찌민",
    ],
    부산: [
      "일본-오사카",
      "일본-후쿠오카",
      "중국-상하이",
      "중국-광저우",
      "중국-베이징",
      "중국-선양",
      "중국-항저우",
      "중국-웨이하이",
      "오세아니아-사이판",
    ],
    청주: ["중국-베이징"],
    무안: ["중국-베이징"],
    제주: ["일본-후쿠오카"],
  };

  const startArea = () => {
    const domestic = Object.keys(route);
    const overseas = Object.values(route).flat();
    return [...domestic, ...overseas];
  };

  const arriveArea = () => {
    const routeEntries = Object.entries(route);
    let domestic = routeEntries.filter(el => el[1].includes(dataForm["출발지역"]));
    domestic = domestic.map(el => el[0]);
    const overseas = route[dataForm["출발지역"]];
    if (Array.isArray(overseas)) return [...overseas];
    else return [...domestic];
  };

  return (
    <div className="calc_travel">
      <div className="travel_wrap step_box step09">
        <div className="q_item">
          <h5 className="question small">탑승 인원을 입력해주세요.</h5>
          <div className="input_box input_ty02 d-flex flex-ac">
            <input type="text" placeholder="숫자만 입력" data-type="탑승인원" value={dataForm["탑승인원"] || ""} onChange={handleInput} />
            <span>명/총</span>
          </div>
        </div>
        <div className="q_item">
          <h5 className="question small">출발/도착 나라를 선택해주세요.</h5>
          <div
            className={"input_box input_ty02 select_input " + (selectList.start ? "active" : "")}
            onClick={() => {
              handleSelectBox("start");
            }}
          >
            <input type="text" placeholder="출발지" value={dataForm["출발지역"] || ""} readOnly />
            {selectList.start && (
              <ul className="select_box">
                {startArea().map((el, idx) => {
                  return (
                    <li key={idx} data-value={el.includes("-") ? el.split("-")[1] : el} data-type="출발지역" onClick={handleInput}>
                      {el}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div
            className={"input_box input_ty02 select_input " + (selectList.arrive ? "active" : "")}
            onClick={() => {
              handleSelectBox("arrive");
            }}
          >
            <input type="text" placeholder="도착지" value={dataForm["도착지역"] || ""} readOnly />
            {selectList.arrive && (
              <ul className="select_box">
                {dataForm["출발지역"] &&
                  arriveArea().map((el, idx) => {
                    return (
                      <li key={idx} data-value={el.includes("-") ? el.split("-")[1] : el} data-type="도착지역" onClick={handleInput}>
                        {el}
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
